import "./App.css";
// import axios from "axios";
// import webLogo from "./assests/webLogo.png";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Success from './images/success.svg';

function SuccessPayment(props) {
  // console.log("Success:",props)
  // console.log("props.razorpay_payment_id:",props.props.razorpay_payment_id)
  const navigate = useNavigate();
  const onClick = () => {
    navigate("/");
  };

  useEffect(()=>
  {
  //   setTimeout(function(){
  //     navigate("/");
  //  }, 10000);
  },[])
  return (
    <div class="main-card">
      <div class="card-outer">
        <div class="card-body" style={{ textAlign: "center" }}>
          {/* <img src="img_avatar.png" alt="Avatar" style="width:100%"> */}

          <img src={Success}></img>
          <p className="header3">Payment Successful</p>
          <p className="labelMessage1">
            Reference Id : {props.props.razorpay_payment_id}
          </p>
           <strong  className="labelMessage2" ><a href="https://chat.whatsapp.com/J6mYenOdkBsGwpbArNNlYk" target="_blank">https://chat.whatsapp.com/J6mYenOdkBsGwpbArNNlYk</a></strong>
          <p className="labelMessage2">please click this link to join the Bailey T&D whatsapp group <br />{" "}
             where class links will be posted:-
          </p>
          <p className="labelMessage2">
            Note : Sometimes Activation will take upto 30 mins. kindly be <br />{" "}
            patient we will notify once activated
          </p>
          <button class="btn btn-success" style={{backgroundColor:"#4CD964",border:"0"}} onClick={onClick}>
            Redirect to Home
          </button>
        </div>
      </div>
    </div>
  );
}

export default SuccessPayment;
