// import logo from "./logo.svg";
import "./App.css";
import axios from "axios";
import webLogo from "./assests/webLogo.png";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

function App() {
  const [user, setUser] = useState({
    user_exists: false,
    email: null,
  });
  const [message, setMessage] = useState("");
  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(false);
  const offerButtonRef = useRef(null);
  const navigate = useNavigate();
  const [enroleBtn,setEnrolbtn]=useState(true)

  // const emailState = {
  //   email: '',
  //   error: ''
  // }
  // const [email,setEmail]=useState(emailState)

  const checkMail = async (e) => {
    setErr("");
    setLoading(true);
    e.preventDefault();
    const mail = e.target[0].value;
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

   
     
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}user-exists/${mail}/?campaign=1`)
      .then((res) => {
        setEnrolbtn(() => {
          return res.data?.user_email ? false: true
        })
        setUser(res.data);
        setTimeout(() => {
          setErr(res.data.user_exists ? false : true);
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        // console.log('Error',err.response.data);
        if(err.response.data?.message)
        {
          setMessage(err.response.data?.message)
          alert(err.response.data?.message)
        }
        setTimeout(() => {
          setLoading(false);
        }, 500);
      });
  


  };

  // function emailValidation(){
  //   const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  //   if(!email || regex.test(email) === false){
  //       // this.setState({
  //       //     error: "Email is not valid"
  //       // });
  //       setEmail({
  //         error: "Email is not valid"
  //     })
  //       return false;
  //   }
    
  //   return true;
  // }

  const getPayment = () => {
    (function () {
      var d = document;
      var x = !d.getElementById("razorpay-embed-btn-js");
      if (x) {
        var s = d.createElement("script");
        s.defer = !0;
        s.id = "razorpay-embed-btn-js";
        s.src = "https://cdn.razorpay.com/static/embed_btn/bundle.js";
        d.body.appendChild(s);
      } else {
        var rzp = window["_rzp_"];
        rzp && rzp.init && rzp.init();
      }
    })();
  };

  useEffect(() => {
    getPayment();
  }, []);

 
  return (
    <>
      <div className="background-page">
        <div className="main_modal">
          <div className="imgDiv">
            <img
              style={{ height: "100%", width: "100%" }}
              src={webLogo}
              alt="web"
            />
          </div>
          <div className="main_title">Bailey 28th T&Ds Course :</div>
          <p style={{ fontSize: "14px" }}>
            Enter the email ID linked to your Surgtest app profile to check for
            offers.
          </p>
          <form autoComplete="off" onSubmit={checkMail} className="model_form">
            <div className="input-container ic1">
              <input
                id="firstname"
                className="input"
                type="email"
                placeholder="Enter Your Mail "
                required
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                onChange={(e) => {
                  setUser(true);
                  setErr(null);
                }}
              />
              
            </div>
            {err === false ? (
              <p className="successMsg">
                &#x1F600; Congratulations! You are eligible for the Surgtest
                loyalty discount on this course!
              </p>
            ) : err === true ? (
              <p className="ErrorMsg">
                &#x1F615; Sorry! You are not eligible for the Surgtest loyalty
                discount on this course!
              </p>
            ) : (
              ""
            )}
            {loading ? (
              <p className="check">Checking...</p>
            ) : !err && !user.user_exists ? (
              <button type="submit" className="check_for_offer">
                Check for Offer
              </button>
            ) : (
              ""
            )}
          </form>
          <div className="desDiv">
            <p className="descriptionHeading">
              {" "}
              <span className="stepHeading">Course Highlights :</span>
            </p>
            <p className="description">
              <span className="step">🗝️</span> Updates from all the 92 chapters
              of the 28th edition of Bailey & Love will be covered. If there are
              no updates, key points will be revised. <br />{" "}
            </p>
            <p className="description">
              <span className="step">✨</span> Classes will take place over
              ‘Zoom’ every weekday from 12th Jan until around 26th May, 2023.{" "}
              <br />
            </p>

            <p className="description">
              <span className="step">⏳</span> Each day, class will start at 8
              p.m. and is expected to go on for 1 hr or 1.5 hrs.
            </p>
            <p className="description">
              <span className="step">🎥</span> Recordings of each Bailey 28th
              T&D session will be available on the Surgtest app until NEET SS
              2023.
            </p>
            <p className="description">
              <span className="step">🥳</span> Discounts for existing Surgtest
              subscribers available.
            </p>
            <p className="description">
              <span className="step">🏷️</span> Discounts upto 40% for adding on
              a Surgtest MCQ Bank or Video lecture series along with T&D
              enrollment available.
            </p>
            <p className="description">
              <span className="step">❌</span> There will be no classes on 2
              days of Pongal, on Holi, on Good Friday and all Saturdays and
              Sundays.
            </p>
          </div>

          <div className="desDiv">
            <div className="table-container">
              <table className="table">
                <thead>
                  <tr>
                    <th>Courses</th>
                    <th>Course Fees(incl.of GST)</th>
                    <th>Total Fees(incl.of GST)</th>
                    <th>Payment</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-label="Courses">Bailey 28th T&Ds </td>
                    <td data-label="Course Fees"> ₹1,999 </td>
                    {user?.user_exists ? (
                      <td data-label="Total Fees">
                        <del style={{ marginRight: "1vw", color: "#58585" }}>
                          ₹1,999{" "}
                        </del>{" "}
                        ₹1,749{" "}
                      </td>
                    ) : (
                      <td data-label="Total Fees">₹1,999</td>
                    )}
                    <td className="payBtn">
                      <button
                        disabled={enroleBtn}
                        className={`btn-pay ${!enroleBtn? 'undisable' :'diableBtn'} `}
                        onClick={() => {
                          navigate("/payment", {
                            state: {
                              user,
                            },
                          });
                        }}
                        ref={offerButtonRef}
                      >
                        Enroll Now
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="desDiv">
            <p className="descriptionHeading">
              {" "}
              <span className="stepHeading">Course FAQ's :</span>
            </p>
            <p className="description">
              <span className="step">Q.</span> How will the Bailey 28th T&D
              classes take place? <br />{" "}
              <p className="identify">
                <span className="step">A.</span> The classes will take place
                online over Zoom
              </p>
            </p>
            <p className="description">
              <span className="step">Q.</span> Will video recordings be made
              available? <br />{" "}
              <p className="identify">
                <span className="step">A.</span> Yes, video recordings of all
                Bailey 28th T&D course sessions will be made available to
                students enrolled in the Bailey 28th T&D course free of charge.
                Students enrolled in this T&D course need not pay an additional
                fee to access recordings.
              </p>
            </p>
            <p className="description">
              <span className="step">Q.</span> When will video recordings be
              made available? <br />{" "}
              <p className="identify">
                <span className="step">A.</span> The recording of each session
                could take a couple of weekdays to be edited & uploaded on the
                app.
              </p>
            </p>
            <p className="description">
              <span className="step">Q.</span> How long will I have access to
              video recordings? <br />{" "}
              <p className="identify">
                <span className="step">A.</span> You will have access to the
                Bailey 28th T&D session recordings until the NEETSS 2023 exam
                takes place.
              </p>
            </p>
            <p className="description">
              <span className="step">Q.</span> Can I download the Bailey 28th
              T&D course recordings for viewing later? <br />{" "}
              <p className="identify">
                <span className="step">A.</span> No, the recordings can only be
                streamed from the Surgtest app.
              </p>
            </p>
          </div>

          <div className="desDiv">
            <p className="descriptionHeading">
              {" "}
              <span className="stepHeading">Terms & Conditions :</span>
            </p>
            <p className="description">
              <span className="step">1.)</span> Important chapters may be pushed
              ahead of schedule and certain chapters might be pushed later in
              the schedule according to faculty convenience and importance of
              topics <br />
            </p>
            <p className="description">
              <span className="step">2.)</span> Bailey 28th T&D course is a live
              & online course that will begin on the 12th of Jan'23. <br />
            </p>
            <p className="description">
              <span className="step">3.)</span> It will take place over 'Zoom'
              or any other similar platform for video conferencing. <br />
            </p>
            <p className="description">
              <span className="step">4.)</span> Video recordings of a session
              will only be available only after that particular session has
              taken place and only available for viewing in the Surgtest app.{" "}
              <br />
            </p>
            <p className="description">
              <span className="step">5.)</span> The Bailey 28th T&D course
              recordings are not available for subscription separately as of
              now. It is meant to help course participants catch up on what they
              missed in the live class. <br />
            </p>
            <p className="description">
              <span className="step">6.)</span> Terms and Conditions are subject
              to change <br />
            </p>
            <p className="description">
              <span className="step">7.)</span> Offers are subject to change.{" "}
              <br />
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
