import React from "react";
import { useRoutes } from "react-router-dom";
import App from "./App";
import InitialPayment from "./InitialPayment";

function Routes() {
  let element = useRoutes([
    {
      path: "/",
      element: <App />,
    },
    { path: "/payment", element: <InitialPayment /> },
  ]);

  return element;
}

export default Routes;
