import "./App.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { loadScript } from "./utils";
import SuccessPayment from "./SuccessPayment";
import FailedPayment from "./FailedPayment";
import { useFormik } from "formik";
import * as yup from "yup";
import { rupee } from "./utils";


function InitialPayment() {
  const location = useLocation();
  // location.state.user will have all the info for the user

  const isUser = location.state.user.user_exists,
    mail = location.state.user.user_email;

  const purchaseReqProp = {
    total: 0,
    discount: 0,
    noofcourse: 0,
    data: {
      course_pk: 0,
      course_sub_type: 0,
      course_duration: 0,
      discount: 0,
    },
  };

  const [isVisible, setIsVisible] = useState(0);
  const [paymentResponse, setPaymentResponse] = useState({});
  const [course_info, setCourse_Info] = useState([]);
  const [user, setUser] = useState({
    email: "",
    name: "",
    phone: "",
  });
  const [defaultPrice, setDefaultPrice] = useState(purchaseReqProp);
  const [sumofmcqvalue, setSumOfMCQValue] = useState(purchaseReqProp);
  const [sumofvideovalue, setSumofVideoValue] = useState(purchaseReqProp);
  const [message, setMessage] = useState("");

  const formik = useFormik({
    initialValues: user,
    enableReinitialize: true,
    validationSchema: yup.object({
      name: yup
        .string()
        // .max(20, "Name should not exceed 20 Characters")
        .matches(/^[a-zA-Z0-9 !@#$%^&*)(]{2,20}$/, 'User should only enter alphabetic character in the name input field')
        .required("Please enter name"),
        // .required("User should only enter alphabetic character in the name input field"),
      phone: yup
        .string()
        .min(10,"Phone number should not be lesser than 10 digits")
        .max(10,"Phone number should not exceed 10 digits")
        // .max('len', 'Phone number should not exceed 10 Characters', val => val.length <= 10)
        .required("Please enter phone number"),
      email: yup
        .string()
        .email("Invalid email address")
        .required("Please enter email ID"),
    }),

    onSubmit: (values) => {
  
      let courselist = [];
      if (sumofmcqvalue.data.course_pk > 0) {
        courselist.push(sumofmcqvalue.data);
      }

      if (sumofvideovalue.data.course_pk > 0) {
        courselist.push(sumofvideovalue.data);
      }

      if (defaultPrice.data.course_pk > 0) {
        courselist.push(defaultPrice.data);
      }
      const postData = {
        is_user: isUser,
        name: values.name,
        email: values.email,
        phone: values.phone,
        course_info: courselist,
      };
      //  console.log('postData :',postData);
      purchase(postData);
      //  setIsVisible(1)
      //  setIsVisible(-1)
    },
  });

  function emailValidation(){
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if(!this.state.email || regex.test(this.state.email) === false){
        this.setState({
            error: "Email is not valid"
        });
        return false;
    }
    return true;
  }

  function reSetAll() {
    //setIsVisible(0)
    //setPaymentResponse({})
    // setCourse_Info([])
    // setUser({})
    setDefaultPrice(purchaseReqProp);
    setSumOfMCQValue(purchaseReqProp);
    setSumofVideoValue(purchaseReqProp);
  }

  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
  });

  const handleMCQClick = (event) => {
    var checkboxes = document.getElementsByName("mcq");
    checkboxes.forEach((item) => {
      if (item !== event.target) {
        item.checked = false;
      }

      if (item === event.target) {
        if (item.checked === true) {
          setSumOfMCQValue({
            total: Number(item.getAttribute("total")),
            discount: Number(item.getAttribute("discount")),
            noofcourse: 1,
            data: {
              course_pk: Number(item.getAttribute("course_id")),
              course_sub_type: Number(item.getAttribute("variant_type")),
              course_duration: Number(item.getAttribute("course_duration")),
              discount: Number(item.getAttribute("discount")),
            },
          });
        } else {
          setSumOfMCQValue(purchaseReqProp);
        }
      }
    });
  };

  const handleVideoClick = (event) => {
    var checkboxes = document.getElementsByName("video");
    checkboxes.forEach((item) => {
      if (item !== event.target) {
        item.checked = false;
      }

      if (item === event.target) {
        if (item.checked === true) {
          //setSumofVideoValue(Number(item.getAttribute("total")));
          setSumofVideoValue({
            total: Number(item.getAttribute("total")),
            discount: Number(item.getAttribute("discount")),
            noofcourse: 1,
            data: {
              course_pk: Number(item.getAttribute("course_id")),
              course_sub_type: Number(item.getAttribute("variant_type")),
              course_duration: Number(item.getAttribute("course_duration")),
              discount: Number(item.getAttribute("discount")),
            },
          });
        } else {
          setSumofVideoValue(purchaseReqProp);
        }
      }
    });
  };

  const handleTextChange = (e) => {
    setUser((user) => ({ ...user, [e.target.name]: e.target.value }));
  };

  const getAllCourse = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}campaign/bailey-course-details/${
          location.state.user.user_exists === true ? 1 : 2
        }${mail ? `?email=${mail}` : ""}`
      )
      .then((res) => {
        setCourse_Info(res.data.course_info);
        const headerCourseData = res.data.course_info.filter(
          (item) => item.bailey_course
        );
        if (headerCourseData && headerCourseData.length > 0) {
          setDefaultPrice({
            total: Number(headerCourseData[0].total),
            discount: Number(headerCourseData[0].discount),
            noofcourse: 1,
            data: {
              course_pk: Number(headerCourseData[0].course_id),
              course_sub_type: Number(headerCourseData[0].variant_type),
              course_duration: Number(headerCourseData[0].course_duration),
              discount: Number(headerCourseData[0].discount),
            },
          });
        }
        // console.log("res.data.user", res.data.user);
        setUser({
          name: res?.data?.user?.name || "",
          email: res?.data?.user?.email || "",
          phone: res?.data?.user?.phone || "",
        });
        setTimeout(() => {
          // setErr(res.data === "True" ? false : true);
          // setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setTimeout(() => {
          // setLoading(false);
        }, 500);
      });
  };

  const purchase = async (postData) => {
    // setErr("");
    // setLoading(true);

    const headers = {
      Authorization: "Token e0de7cf5c7aaea8af84fec9ff450bfaa88574c4f",
      "Content-Type": "application/json",
    };

    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}campaign/purchase/?campaign=1`,
        postData,
        { headers }
      )
      .then((res) => {
        const options = {
          key: process.env.REACT_APP_RAZOR_PAY_KEY,
          currency: "INR",
          amount: res.data?.total,
          name: "Bailey 28th T&Ds Course enrollment",
          description: "surgtest",
          image:
            "https://surgtest.s3.ap-south-1.amazonaws.com/static/images/logo/paymantLogo.png",
          order_id: res.data?.order_id,
          email: res.data.user_registration.user?.email,
          prefill: {
            contact: res.data.user_registration.user?.phone,
            email: res.data.user_registration.user?.email,
            name: res.data.user_registration.user?.name,
          },
          handler(response) {
            // console.log('payment response :',response)
            setPaymentResponse(response);
            setIsVisible(1);
              reSetAll();
            // if (response.razorpay_payment_id != '') {
            //   setIsVisible(1);
            //   reSetAll();
            // } else {
            //   setIsVisible(-1);
            // }
            // navigate('/success')
            // resetValues()
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.on("payment.failed", function (response) {
          // console.log("payment failed response :",response);
          setPaymentResponse(response);
          setIsVisible(-1);
          // alert(response.error.code);
          // alert(response.error.description);
          // alert(response.error.source);
          // alert(response.error.step);
          // alert(response.error.reason);
          // alert(response.error.metadata.order_id);
          // alert(response.error.metadata.payment_id);
        });

//failure@razorpay

//const paymentObject = new window.Razorpay(options);
        paymentObject.open();

        // setTimeout(() => {
        //   // setErr(res.data === "True" ? false : true);
        //   // setLoading(false);
        // }, 500);
      })
      .catch((err) => {
        if(err.response.data?.message)
        {
          setMessage(err.response.data?.message[0])
          alert(err.response.data?.message[0])
        }
        setTimeout(() => {
          //  setLoading(false);
        }, 500);
      });
  };

  useEffect(() => {
    getAllCourse(); // eslint-disable-next-line
  }, []);

  // const submitClick = (e) => {
  //   e.preventDefault();
  //   const form = this.formRef.current;
  //   if (!form.checkValidity()) {
  //       form.reportValidity()
  //       return
  //   }
  //   let courselist = [];
  //   if (sumofmcqvalue.data.course_pk > 0) {
  //     courselist.push(sumofmcqvalue.data);
  //   }

  //   if (sumofvideovalue.data.course_pk > 0) {
  //     courselist.push(sumofvideovalue.data);
  //   }

  //   if (defaultPrice.data.course_pk > 0) {
  //     courselist.push(defaultPrice.data);
  //   }
  //   const postData = {
  //     is_user: isUser,
  //     name: user.name,
  //     email: user.email,
  //     phone: user.phone,
  //     course_info: courselist,
  //   };
  //   purchase(postData);
  // };

  const titleCourseData = course_info
  .filter((item) => item.bailey_course)
  .map((item,i) => {
    return (
       <>
          <p className="header2">{item.name}</p>
          <p className="header3">{rupee.format(item.total)}</p>
        </>
    )
  })
  const headerCourseData = course_info
    .filter((item) => item.bailey_course)
    .map((item,i) => {
      return (
        // <>
        //   <p className="header2">{item.name}</p>
        //   <p className="header3">₹ {item.total}</p>
        // </>
        <div class="cardData" key={i}>
          <div class="card-body">
            <input
              type="checkbox"
              // className="mcqCheckBox"
              id={item.id}
              name="video"
              class="check-with-label"
              variant_type={item.variant_type}
              value={item.name}
              discount={item.discount}
              course_id={item.course_id}
              course_duration={item.course_duration}
              total={item.total}
              readOnly={true}
              checked
              // onChange={(event) => handleMCQClick(event)}
              // style={{background: checked? "green":""}}
            />
            <span className="checkboxHeader" for={item.id}>
              {item.name} {rupee.format(item.total)}
            </span>
            {item.discount>0 && (
              <>
                <br />
                <span className="checkboxFooter" for={item.id}>
                  You Save {rupee.format(item.discount)}
                </span>
              </>
            )}
          </div>
        </div>
      );
    });

  return (
    <form onSubmit={formik.handleSubmit}>
      <header className="header">
        <h1 className="logo">
          <img
            alt="Surgtest Bailey"
            className="logo_img"
            src="surgtest_logo.svg"
          ></img>
        </h1>
        <ul className="main-nav">
          <li>
            {/* <img
              alt="Surgetest "
              style={{ width: "82px", height: "48px" }}
              src="sideuser.svg"
            ></img> */}
          </li>
        </ul>
      </header>
      {isVisible === 0 && (
        <div>
          <div className="card">
            {/* <img src="img_avatar.png" alt="Avatar" style="width:100%"> */}
            <div className="container">
              {/* <p className="header1">You Have Purchased</p> */}
              {titleCourseData}
              <p className="subLabel">(incl.of GST)</p>
              {/* <hr></hr> */}
              <form autoComplete="off" onSubmit>
                
                <div className="row">
                  <div className="col-sm-4" style={{ textAlign: "left" }}>
                    <label className="txtlabel" for="txtName">
                      Name
                    </label>

                    <input
                      id="txtName"
                      className="txtCss"
                      type="name"
                      name="name"
                      placeholder="Your name"
                      // defaultValue={user.name}
                      // value={user.name}
                      
                      onChange={(e) => handleTextChange(e)}
                      // required
                      readOnly={isUser ? true : false}
                      {...formik.getFieldProps("name")}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <span style={{ color: "red" }}>{formik.errors.name}</span>
                    ) : null}
                  </div>
                  <div className="col-sm-4" style={{ textAlign: "left" }}>
                    <label className="txtlabel" for="txtEmail">
                      Email id
                    </label>
                    <input
                      id="txtEmail"
                      className="txtCss"
                      type="email"
                      name="email"
                      placeholder="Your mail id "
                      // defaultValue={user.email}
                      // value={user.email}
                      // required
                      readOnly
                      onChange={(event) => handleTextChange(event)}
                      {...formik.getFieldProps("email")}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <span style={{ color: "red" }}>
                        {formik.errors.email}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-sm-4" style={{ textAlign: "left" }}>
                    <label className="txtlabel" for="txtPhoneNumber">
                      Phone Number
                    </label>
                    <input
                      id="txtPhoneNumber"
                      className="txtCss"
                      type="number"
                      name="phone"
                      placeholder="Your phone number "
                      // defaultValue={user.phone}
                      // value={user.phone}
                      // required
                      readOnly={isUser ? true : false}
                      onChange={(e) => handleTextChange(e)}
                      {...formik.getFieldProps("phone")}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <span style={{ color: "red" }}>
                        {formik.errors.phone}
                      </span>
                    ) : null}
                  </div>
                </div>
                <hr></hr>
                <div class="row">
                  <div class="col-sm-12">
                  {headerCourseData}
                  </div>
                </div>
                <div className="row">
                <div className="col-sm-6">
                    {course_info.length > 0 ? (
                      course_info
                        .filter(
                          (item) =>
                            item.variant_type === 5 && !item.bailey_course
                        )
                        .map(function (item, i) {
                          return (
                            <div class="cardData" key={i}>
                              <div class="card-body">
                                <input
                                  type="checkbox"
                                  class="check-with-label"
                                  id={item.id}
                                  name="video"
                                  variant_type={item.variant_type}
                                  value={item.name}
                                  discount={item.discount}
                                  course_id={item.course_id}
                                  course_duration={item.course_duration}
                                  total={item.total}
                                  onChange={(event) => handleVideoClick(event)}
                                  // style={{background: checked? "green":""}}
                                />
                                <span className="checkboxHeader" for={item.id}>
                                  {item.name} {rupee.format(item.total)}
                                </span>
                                {item.discount > 0 && (
                                  <>
                                    <br />
                                    <span
                                      className="checkboxFooter"
                                      for={item.id}
                                    >
                                      You Save {rupee.format(item.discount)}
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                          );
                        })
                    ) : (
                      <></>
                    )}
                  </div>
                  <div class="col-sm-6">
                    {course_info.length > 0 ? (
                      course_info
                        .filter((item) => item.variant_type === 4)
                        .map(function (item, i) {
                          return (
                            <div class="cardData" key={i}>
                              <div class="card-body">
                                <input
                                  type="checkbox"
                                  class="check-with-label"
                                  id={item.id}
                                  name="mcq"
                                  variant_type={item.variant_type}
                                  value={item.name}
                                  discount={item.discount}
                                  course_id={item.course_id}
                                  course_duration={item.course_duration}
                                  total={item.total}
                                  onChange={(event) => handleMCQClick(event)}
                                  // style={{background: checked? "green":""}}
                                />
                                <span className="checkboxHeader" for={item.id}>
                                  {item.name} {rupee.format(item.total)}
                                </span>
                                {item.discount > 0 && (
                                  <>
                                    <br />
                                    <span
                                      className="checkboxFooter"
                                      for={item.id}
                                    >
                                      You Save {rupee.format(item.discount)}
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                          );
                        })
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="card_bottom">
            {/* <img src="img_avatar.png" alt="Avatar" style="width:100%"> */}
            <div class="card-body">
              <div className="row">
                <div className="col-sm-4">
                  <span className="txtCourse">
                    <label>
                      {sumofmcqvalue.noofcourse +
                        sumofvideovalue.noofcourse +
                        defaultPrice.noofcourse}{" "}
                      Courses
                    </label>
                    <label>
                      &nbsp;You Save{" "}<span className="txtSavePrice">
                      {rupee.format(sumofmcqvalue.discount +
                        sumofvideovalue.discount +
                        defaultPrice.discount)}</span>
                    </label>
                  </span>
                  <p className="header3">
                    Total{" "}
                    {rupee.format(sumofmcqvalue.total +
                      sumofvideovalue.total +
                      defaultPrice.total)}
                  </p>
                </div>
                <div className="col-sm-4"></div>
                <div className="col-sm-4">
                  <button className="btnCss" type="submit">
                    Proceed to Pay
                  </button>
                </div>
              </div>
              </div>
          </div>
        </div>
      )}
      {isVisible === 1 && <SuccessPayment props={paymentResponse} />}
      {isVisible === -1 && <FailedPayment props={paymentResponse} />}
    </form>
  );
}

export default InitialPayment;
